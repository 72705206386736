import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toaster from "@meforma/vue-toaster";
import 'vue-toast-notification/dist/theme-sugar.css';
import i18n from '../src/i18n';
import VueGtag from 'vue-gtag';
// require("../src/main");

const app = createApp(App);

  app.use(router)
  app.use((i18n()))

  app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_ID },
    // enabled: true,
    // debug: true
  }, router);

  
 app.use(Toaster,{
  duration:3000,
  position: "top-right",
})
 app.mount("#app")
import moment from "moment";
import { jwtDecode } from "jwt-decode";

export const validateEmail = (email) => {
    const re =
        /^(([^`<>()[\]\\.,;:\s@'"]+(\.[^<>()[\]\\.,;:\s@'"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};


export const validateToken = (token) => {
    try {
        if (token) {
            var decodedToken = jwtDecode(token);
            if (decodedToken.id != null) return true;
            else return false;
        } else return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};


export const validatePhoneNumber = (phoneNumber) => {
    const re = /^[0-9]{10}$/gm;
    return re.test(String(phoneNumber));
};

export const validateUserNum = (userNum) => {
    const re = /^([1-9]{1,5})?$/;
    return re.test(String(userNum));
};

export const validatePassword = (password) => {
    const re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return re.test(String(password));
};

export const validateName = (name) => {
    const re = /^[A-Za-z\s]+$/;
    return re.test(String(name));
};

export const getFormattedDate = (value) => {
    let date = new Date(value);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
};
export const validateURL = (url) => {
    // URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
};
export const validateAccountNumber = (accountNumber) => {
    // Example: Check if the account number has between 6 and 12 digits
    const re = /^\d{6,12}$/;
    return re.test(String(accountNumber));
};

export const validateFax = (fax) => {
    // Regular expression for a basic fax number validation
    const faxRegex = /^[0-9()+\-. ]+$/;

    return faxRegex.test(fax);
};

export const dateFormat = (date) => {
    const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ];
    let current_datetime = new Date(date);
    let formatted_date =
        current_datetime.getDate() +
        " " +
        months[current_datetime.getMonth()] +
        " " +
        current_datetime.getFullYear();
    return formatted_date;
};

export const momentDateFormat = (date) => {
    return moment(date).format("MM-DD-YYYY hh:mm A");
};

export const ConvertDateFormat = (date, format) => {
    return moment(date).format(format);
}

export const isEmpty = (inputObject) => {
    return Object.keys(inputObject).length === 0;
};

// export const validateToken = (token) => {
//     try {
//         if (token) {
//             var decodedToken = jwtDecode(token);
//             if (decodedToken.id != null) return true;
//             else return false;
//         } else return false;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// };

export const getFirstLetters = (str) => {
    // Show on profile display the first two letters
    if (str) {
        return str
            .split(" ")
            .map((word) => word[0])
            .join("")
            .slice(0, 2);
    }
    return str;
};